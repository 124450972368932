import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import React from "react";

const Collapse = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <motion.div
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      exit="closed"
      variants={{
        closed: {
          height: 350,
          opacity: 1,
        },
        open: {
          height: "auto",
          opacity: 1,
        },
      }}
      transition={{ duration: 1 }}
      className="rounded-2xl overflow-hidden bg-base-100 p-4 relative"
    >
      {!isOpen && (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={
            "flex justify-center gap-2 z-20 text-center absolute bottom-0 inset-x-0 rounded-b-2xl p-3 bg-gradient-to-t from-base-100 from-20% via-base-100/80 via-70% to-base-100/10"
          }
        >
          <ChevronDown className="animate-bounce" />
        </button>
      )}
      {children}
    </motion.div>
  );
};

export default Collapse;
