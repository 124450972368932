import {
  Bitcoin,
  ClockArrowDown,
  CreditCard,
  HandCoins,
  ShieldCheck,
} from "lucide-react";

export default {
  notifications: [
    {
      name: "WhatsApp",
      message: "Use 950 572 to verify your WhatsApp account.",
    },
    {
      name: "Facebook",
      message: "Use 264 111 to verify your Facebook account.",
    },
    {
      name: "Telegram",
      message: "Telegram Code: 35499",
    },
    {
      name: "Netflix",
      message: "Your Netflix verification code is 523611.",
    },
    {
      name: "Amazon",
      message: "Your Amazon Web Services (AWS) verification code is: 2462",
    },
    {
      name: "Tinder",
      message: "Your OkCupid code is 736281 Don’t share",
    },
    {
      name: "Google",
      message: "G-529963 is your Google verification code",
    },
    {
      name: "Steam",
      message: "Use code 84901 to confirm your phone on Steam",
    },
    {
      name: "Uber",
      message: "Code Uber : 3245",
    },
    {
      name: "Instagram",
      message: "Use 719 478 to verify your Instagram account.",
    },
    {
      name: "eBay",
      message: "Your one-time eBay PIN is 5613",
    },
  ],
  faq: [
    {
      q: "home.faq.q1",
      a: "home.faq.a1",
    },
    {
      q: "home.faq.q2",
      a: "home.faq.a2",
    },
    {
      q: "home.faq.q3",
      a: "home.faq.a3",
    },
    {
      q: "home.faq.q4",
      a: "home.faq.a4",
    },
    {
      q: "home.faq.q5",
      a: "home.faq.a5",
    },
    {
      q: "home.faq.q6",
      a: "home.faq.a6",
    },
  ],
  reviews: [
    {
      starCount: 5,
      name: "Christopher Johnson",
      from: "App Store",
      reviewText:
        "At first, I was skeptical, but this app quickly won me over! Definitely one of my favorite downloads ever.",
    },
    {
      starCount: 5,
      name: "Ogwo George",
      from: "Google Play",
      reviewText:
        "This app surpasses both Wabi and Numero. Highly affordable with precise navigation. More coverage and more numbers.",
    },
    {
      starCount: 5,
      name: "Jonathan Doe",
      from: "Google Play",
      reviewText:
        "This app is excellent, fast, and affordable. I love it! Getting a virtual number for any social media platform is a breeze.",
    },
    {
      starCount: 5,
      name: "Emily Davis",
      from: "Google Play",
      reviewText:
        "I love the app's interface; it's smooth and easy to navigate.",
    },
    {
      starCount: 5,
      name: "Anthony Parker",
      from: "Google Play",
      reviewText:
        "Fantastic app! It offers a quick refund process if the verification code doesn’t arrive. Highly trustworthy.",
    },
    {
      starCount: 5,
      name: "Michael Smith",
      from: "App Store",
      reviewText: "Quick, safe, and professional. Exactly what I needed.",
    },
    {
      starCount: 5,
      name: "James Emetu",
      from: "App Store",
      reviewText:
        "An excellent second line and verification SIM for any online application requiring SMS verification.",
    },
    {
      starCount: 4,
      name: "Emmanuel Gabriel",
      from: "Google Play",
      reviewText:
        "The service has been good so far, and I'm looking forward to even better service in the future.",
    },
    {
      starCount: 4,
      name: "Sarah Thompson",
      from: "Google Play",
      reviewText:
        "The app is incredibly useful and easy to navigate. Their support team is fantastic and resolved my issue within minutes. Highly recommend!",
    },
  ],
  benefits: [
    {
      Icon: ClockArrowDown,
      name: "home.benefits.instantDelivery",
    },
    {
      Icon: Bitcoin,
      name: "home.benefits.cryptoAccepted",
    },

    {
      Icon: CreditCard,
      name: "home.benefits.visaAndCreditCardAccepted",
    },
    {
      Icon: CreditCard,
      name: "home.benefits.onlyPayForReceivedCodes",
    },
    {
      Icon: ShieldCheck,
      name: "home.benefits.secureAndAnonymous",
    },
  ],
};
